/*
 * @Description: 客服
 * @Author: wl
 */
import service from '@/utils/request'

/**
 * 获取用户信息
 * **/
export function getUserInfo(params) {
  return service({
   url: '/slipper/api/customer/detail',
    method: 'post',
    data: params
  })
}

/**
 * 获取用户状态
 * */
export function getUserState(params) {
  return service({
   url: '/slipper/api/user/use/state',
    method: 'post',
    data: params
  })
}

/**
 * 修改用户状态
 * 入参{”state“：”1：上线，2：离开，3：下线“}
 * */
export function updateUserState(params) {
  return service({
    url: '/slipper/api/user/use/update',
    method: 'post',
    data: params
  })
}

/**
 * 获取会话列表
 * {"searchType":""}
 * 1:正在接入，2：待接入，3：追踪库
 * */
export function getChatList(params) {
  return service({
    url: '/slipper/api/wait/list',
    method: 'post',
    data: params
  })
}

/**
 * 获取常用语
 * */
export function commonList(params) {
  return service({
    url: '/slipper/api/commonly/show',
    method: 'post',
    data: params
  })
}

/**
 * 初始化会话
 * */
export function initChat(params) {
  return service({
    url: '/slipper/api/conversation/init',
    method: 'post',
    data: params
  })
}

/**
 * 上传文件
 * */
export function upload(params) {
  return service({
   url: '/slipper/api/file/upload',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

/**
 * 上传截图
 * */
export function uploadBase64Img(params) {
  return service({
    url: '/slipper/api/file/upload/img',
    method: 'post',
    data: params
  })
}

/**
 * 客服转接列表
 * */
export function transferList() {
  return service({
   url: '/slipper/api/user/select/transfer',
    method: 'post'
  })
}

/**
 * 客服转接
 * */
export function transfer(params) {
  return service({
   url: '/slipper/api/conversation/transfer',
    method: 'post',
    data: params
  })
}

/**
 * 关闭会话
 * */
export function closeChat(params) {
  return service({
   url: '/slipper/api/conversation/end',
    method: 'post',
    data: params
  })
}
