import service from '@/utils/request'
// import { parseJson2Param } from '@/utils/index'
// import { getApiBaseUrl } from '@/utils'

/**
 * @description: 验证码
 * @param {*} params
 * @return {*}
 * @author: gumingchen
 */
// export function captchaApi(params) {
//   let result = ''
//   const options = {
//     url: '/backstage/captcha.jpg',
//     method: 'get',
//     params: params
//   }
//   result = `${getApiBaseUrl() + options.url}?${parseJson2Param(options.params)}`
//   return result
// }

/**
 * @description: 登录
 * @param {*} params
 * @return {*}
 * @author: gumingchen
 */
export function loginApi(params) {
  return service({
    url: '/slipper/api/sign/Login',
    method: 'post',
    data: params
  })
}

/**
 * @description: 获取登录用户信息
 * @param {*}
 * @return {*}
 * @author: gumingchen
 */
export function userInfoApi() {
  return service({
    url: '/slipper/api/user/detail',
    method: 'get'
  })
}

/**
 * @description: 获取登录用户权限菜单
 * @param {*}
 * @return {*}
 * @author: gumingchen
 */
export function userMenusApi() {
  return service({
    url: '/api/menu/all',
    method: 'get'
  })
}

/**
 * @description: 修改用户信息
 * @param {*} params
 * @return {*}
 * @author: gumingchen
 */
export function editUserInfoApi(params) {
  return service({
    url: '/api/user/self/update',
    method: 'post',
    data: params
  })
}

/**
 * @description: 退出
 * @param {*}
 * @return {*}
 * @author: gumingchen
 */
export function logoutApi() {
  return service({
    url: '/slipper/api/sign/logout',
    method: 'post'
  })
}

/**
 * @description: 页面关闭调用
 * @return {*}
 * @author: gumingchen
 */
export function closeApp() {
  return service({
    url: '/api/wait/check',
    method: 'get'
  })
}

