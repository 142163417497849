import axios from "axios"
// let apihost = localStorage.getItem("apihost")
// let baseURL = localStorage.getItem("api")

// let baseURL = ''
const request = axios.create({
    // baseURL: 'http://192.168.31.249:9992',//测试服务
    baseURL: 'https://server.hyperlinkvpn.com/api',//正式服务
})
request.defaults.timeout = 10000;

// request拦截器
request.interceptors.request.use(

    config => {

        config.headers['Token'] = sessionStorage.getItem("token") || ''
        config.headers['language'] = localStorage.getItem("language") || 'zh'
        // config.baseURL = baseURL || localStorage.getItem("successApi") || "http://192.168.31.249:9999/"
        // config.baseURL = baseURL || localStorage.getItem("successApi") || "https://apinew.apphyper.com/"
        
        console.log(config.baseURL);

        return config
    }, error => {
        return Promise.reject(error)
    })
export default request