import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import api from './api/index.js';
Vue.prototype.$api = api
// Vue.prototype.VUE_APP_WS_CUSTOMER_URL = 'ws://192.168.31.249:9993/ws'//测试服务
Vue.prototype.VUE_APP_WS_CUSTOMER_URL = 'wss://server.hyperlinkvpn.com/ws'//客服链接~正式服务

import axios from "axios"
Vue.prototype.$axios = axios

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem("token")
  if (token != undefined && token != null || to.path === '/login') {
    next()
  } else {
    // 在为空的情况下，访问的不是'/'， 就直接跳转到'/'
    next({
      path: '/login'
    })
  }
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
