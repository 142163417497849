import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../pages/HomeView.vue";
import login from "../pages/login"
import customerIndex from "../pages/customerIndex"
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "customerIndex",
    component: customerIndex,
  },
  {
    path: "/login",
    name: "login",
    component: login,
  }, {
    path: "/customerIndex",
    name: "customerIndex",
    component: customerIndex,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
