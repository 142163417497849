<template>
  <div class="outside">
    <div class="top_header">
      <div class="time_title" v-if="todayTime !== ''">
        <span class="customer_time">今日客服时段： {{ todayTime }}</span>
      </div>
      <span
        style="
          font-size: 18px;
          color: #000000;
          margin-top: 20px;
          margin-left: 10px;
        "
        >在线客服</span
      >
      <el-radio
        style="margin-left: 30px"
        v-model="radio"
        label="1"
        @change="online"
        >上线</el-radio
      >
      <el-radio v-model="radio" label="2" @change="leave">离开</el-radio>
      <el-radio v-model="radio" label="3" @change="offline">下线</el-radio>
    </div>
    <div style="margin: 20px auto; text-align: left">
      <div class="left">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane :label="tab1" name="first">
            <div class="left_tab">
              <div
                class="No_news_yet"
                style="
                  text-align: center;
                  margin-top: 100px;
                  margin-bottom: 100px;
                "
                v-if="runningChatList.length === 0 && activeName == 'first'"
              >
                <img src="~@/assets/image/icon_chat_empty.png" alt="" />
                <div>暂无消息</div>
              </div>
              <ul v-if="runningChatList.length > 0 && activeName == 'first'">
                <li
                  :style="{
                    backgroundColor:
                      item.isActive === true ? '#F5F6FD' : 'white',
                  }"
                  v-for="(item, position) in runningChatList"
                  :key="position"
                >
                  <div
                    v-if="position !== 0"
                    style="
                      width: auto;
                      height: 1px;
                      background-color: #cbe0fb;
                      margin-bottom: 5px;
                    "
                  />
                  <div style="padding: 10px 10px" @click="clickItem(item)">
                    <div class="item_text">{{ item.userEmail }}</div>
                    <div
                      class="item_transferFrom"
                      v-if="item.transferFrom !== ''"
                    >
                      [{{ item.transferFrom }}转入]
                    </div>
                    <div class="item_top item_text_gray">
                      {{ getUserConnectState(item.connectState) }}、会话时长{{
                        item.connectTime
                      }}
                    </div>
                    <div class="item_top text_style">
                      <span
                        style="font-size: 12px; color: #d9001b"
                        v-if="item.hasNew === 1"
                        >[新]</span
                      >
                      <span
                        style="font-size: 12px; color: #f59a23"
                        v-if="
                          item.hasNew !== 1 &&
                          item.waitTime &&
                          item.newMessage !== ''
                        "
                        >[{{ item.waitTime }}]</span
                      >
                      <span class="item_msg_text" style="margin-left: 3px">{{
                        getChatInfoFormat(item)
                      }}</span>
                    </div>
                    <div
                      class="item_top item_text_gray"
                      v-if="item.labels !== ''"
                    >
                      标签：{{ item.labels }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </el-tab-pane>

          <el-tab-pane :label="tab2" name="second">
            <div class="left_tab">
              <div
                class="No_news_yet"
                style="
                  text-align: center;
                  margin-top: 100px;
                  margin-bottom: 100px;
                "
                v-if="waitChatList.length === 0 && activeName == 'second'"
              >
                <img src="~@/assets/image/icon_chat_empty.png" alt="" />
                <div>暂无消息</div>
              </div>
              <ul v-if="waitChatList.length > 0 && activeName == 'second'">
                <li v-for="(item, position) in waitChatList" :key="position">
                  <div
                    v-if="position !== 0"
                    style="
                      width: auto;
                      height: 1px;
                      background-color: #cbe0fb;
                      margin-top: 5px;
                      margin-bottom: 5px;
                    "
                  />
                  <div class="item_bg" style="padding: 10px 10px">
                    <div class="item_text">
                      [{{ position + 1 }}]{{ item.userEmail }}
                    </div>
                    <div class="item_top item_text_gray">
                      {{ getUserConnectState(item.connectState) }}、等待时长{{
                        item.connectTime
                      }}
                    </div>
                    <div class="item_top item_msg_text text_style">
                      {{ getChatInfoFormat(item) }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="tab3" name="third">
            <div class="left_tab">
              <div
                class="No_news_yet"
                style="
                  text-align: center;
                  margin-top: 100px;
                  margin-bottom: 100px;
                "
                v-if="followChatList.length === 0 && activeName == 'third'"
              >
                <img src="~@/assets/image/icon_chat_empty.png" alt="" />
                <div>暂无消息</div>
              </div>
              <ul v-if="followChatList.length > 0 && activeName == 'third'">
                <li
                  :style="{
                    backgroundColor:
                      item.isActive === true ? '#F5F6FD' : 'white',
                  }"
                  v-for="(item, index) in followChatList"
                  :key="index"
                >
                  <div
                    v-if="index !== 0"
                    style="
                      width: auto;
                      height: 1px;
                      background-color: #cbe0fb;
                      margin-bottom: 5px;
                    "
                  />
                  <div class="outerBox" @click="clickFollowItem(item)">
                    <div class="item_text">{{ item.userEmail }}</div>
                    <div class="item_top item_text_gray">
                      间隔时间{{ item.connectTime }}
                    </div>
                    <div class="item_top item_msg_text text_follow_style">
                      {{ getChatInfoFormat(item) }}
                    </div>
                    <div
                      class="item_top item_text_gray"
                      v-if="item.labels !== ''"
                    >
                      标签：{{ item.labels }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div v-if="!showChatInfo" style="text-align: center">
        <img
          src="~@/assets/image/icon_customer_empty.png"
          style="width: 200px; height: 200px; margin-top: 80px"
        />
        <div style="margin-top: 10px; font-size: 18px; color: #000000">
          {{ getCustomerState() }}
        </div>
        <div style="margin-top: 10px; font-size: 13px; color: #a1afc6">
          {{ getStateInfo() }}
        </div>
      </div>
      <div v-if="showChatInfo">
        <!-- <div class="right">
            <div class="right_layout">
              <el-radio-group v-model="userCheck">
                <el-radio-button label="用户信息" />
                <el-radio-button label="系统信息" />
              </el-radio-group>
              <div>
                <div style="padding: 10px 10px" v-if="userCheck==='用户信息'">
                  <span class="user_info_text">账号：{{ userInfo.userEmail }}</span><br>
                  <span class="user_info_text">昵称：{{ getUserName(userInfo.userName) }}</span><br>
                  <span class="user_info_text">用户标签：种子用户、价值用户</span><br>
                  <span class="user_info_text">当前套餐：{{ getVipType(userInfo.userCombo) }}</span><br>
                  <span class="user_info_text">购买频次：{{ userInfo.userBuy }}次</span><br>
                  <span class="user_info_text">对话频次：{{ userInfo.userDialogue }}次</span>
                </div>
              </div>
              <div style="padding: 10px 10px" v-if="userCheck==='系统信息'">
                <ul>
                  <li
                    v-for="(item,position) in userDeviceList"
                    :key="position">
                    <div @click="item.show = !item.show">
                      <span v-if="item.show">▼</span>
                      <span v-if="!item.show">►</span>
                      <span class="user_info_text">设备{{ position+1 }}</span>
                    </div>
                    <div v-if="item.show" style="margin-top: 5px;margin-bottom: 5px">
                      <div class="user_device_text"> 设备信息：{{ item.equipmentDevice }}</div>
                      <div class="user_device_text"> 系统类型：{{ item.equipmentType }}</div>
                      <div class="user_device_text"> 系统版本：{{ item.equipmentVersion }}</div>
                      <div class="user_device_text" v-if="item.equipmentPcResolution!==''"> 分辨率：{{  getResolution(item.equipmentPcResolution) }}</div>
                      <div class="user_device_text"> 设备接入时间：{{ item.createTime }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="right_layout" style="margin-top: 20px">
              <div class="common">常用语</div>
              <div style="padding: 10px 10px">
                <div>
                  <input
                    v-model="common_search"
                    class="right_input"
                    placeholder="请输入搜索内容">
                  <span class="right_search_text" @click="getCommonList">搜索</span>
                </div>
                <div style="margin-top: 20px;margin-bottom: 20px;text-align: center" v-if="usefulList.length===0">未搜索到相关内容</div>
                <div style="margin-top: 10px;max-height: 200px;overflow-x: hidden;overflow-y: scroll;">
                  <ul>
                    <li
                      v-for="(item,position) in usefulList"
                      :key="position">
                      <div @click="item.show = !item.show" style="margin-top: 5px">
                        <span v-if="item.show" style="cursor: default">▼</span>
                        <span v-if="!item.show" style="cursor: default">►</span>
                        <span class="user_info_text">{{ item.labelName }}</span>
                      </div>
                      <div v-if="item.show" style="margin-top: 10px;margin-bottom: 10px;cursor: default">
                        <div
                          class="common_text"
                          @click="clickCommon(tag)"
                          v-for="(tag,position) in item.commonly"
                          :key="position">
                          {{ position+1 }}.{{ tag }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="right_layout" style="margin-top: 20px">
              <el-radio-group v-model="activeServe">
                <el-radio-button label="服务标记" />
                <el-radio-button label="追踪标记" />
              </el-radio-group>
              <div>
                <div style="padding: 10px 10px" v-if="activeServe==='服务标记'">
                  <div style="margin-top: 10px">会话标签：</div>
                  <div style="margin-right: 10px;margin-top: 15px">
                    <el-tag
                      style="margin: 10px 10px"
                      v-for="tag in tags"
                      :key="tag.labelName"
                      @close="handleClose(tag)"
                      closable>
                      {{tag.labelName}}
                    </el-tag>
                    <span class="add_tag" @click="showAddTag">+</span>
                  </div>
                  <div style="margin-top: 15px">服务备注：</div>
                  <textarea
                    v-model="tag_mark"
                    placeholder="添加服务备注"
                    maxlength="200"
                    onchange="this.value=this.value.substring(0, 200)"
                    onkeydown="this.value=this.value.substring(0, 200)"
                    onkeyup="this.value=this.value.substring(0, 200)"
                    class="mark" />
                  <el-button
                    style="width: 100%;margin-top: 10px"
                    type="primary"
                    @click="clickAddMark"
                    round>提交</el-button>
                </div>
                <div style="padding: 10px 10px" v-if="activeServe==='追踪标记'">
                  <div>是否开启：
                    <el-switch
                      style="float: right"
                      v-model="value"
                      @change="changeFollowStatus"
                      active-color="#13ce66"
                      inactive-color="#eeeeee" />
                  </div>
                  <div style="margin-top: 15px">追踪备注：</div>
                  <textarea
                    v-model="follow_mark"
                    placeholder="添加追踪备注"
                    maxlength="200"
                    onchange="this.value=this.value.substring(0, 200)"
                    onkeydown="this.value=this.value.substring(0, 200)"
                    onkeyup="this.value=this.value.substring(0, 200)"
                    class="mark" />
                  <el-button
                    style="width: 100%;margin-top: 10px"
                    type="primary"
                    @click="changeFollowStatus"
                    round>提交</el-button>
                </div>
              </div>
            </div>
          </div> -->

        <div class="middle">
          <div style="background-color: #f0f2ff; padding: 10px 10px">
            <span style="color: #333333; font-size: 13px"
              >服务单号：{{ conversationCode }}</span
            >
            <span
              style="
                color: #333333;
                font-size: 13px;
                float: right;
                margin-right: 10px;
                cursor: default;
              "
              @click="clickClose"
              >关闭</span
            >
          </div>
          <div class="list" id="chat">
            <ul>
              <li
                class="item"
                style="list-style-type: none"
                v-for="(item, position) in messageList"
                :key="position"
              >
                <div class="chat_time" v-if="isShow(item, position)">
                  {{ getSendTime(item.sendTime) }}
                </div>
                <div v-if="item.conversationType === '1'">
                  <span
                    :class="
                      item.sender.userEmail === email
                        ? 'img_head_dark'
                        : 'img_head'
                    "
                    >{{ item.sender.userName.substring(0, 1) }}</span
                  >
                  <span
                    :class="
                      item.sender.userEmail === email ? 'msg_bg_gray' : 'msg_bg'
                    "
                    >{{ item.conversationContent }}</span
                  >
                </div>
                <div v-if="item.conversationType === '2'">
                  <span
                    :class="
                      item.sender.userEmail === email
                        ? 'img_head_dark'
                        : 'img_head'
                    "
                    >{{ item.sender.userName.substring(0, 1) }}</span
                  >
                  <el-image
                    v-if="item.isVertical"
                    :class="
                      item.sender.userEmail === email
                        ? 'img_msg'
                        : 'img_msg_gray'
                    "
                    style="width: 320px; height: 240px"
                    :src="item.imgUrl"
                    :fit="'cover'"
                    :onError="(src = '@/assets/image/icon_default.jpg')"
                    @click="lookPic(item.imgUrl)"
                    alt=""
                  />
                  <el-image
                    v-if="!item.isVertical"
                    :class="
                      item.sender.userEmail === email
                        ? 'img_msg'
                        : 'img_msg_gray'
                    "
                    style="width: 240px; height: 320px"
                    :src="item.imgUrl"
                    :fit="'cover'"
                    :onError="(src = '@/assets/image/icon_default.jpg')"
                    @click="lookPic(item.imgUrl)"
                    alt=""
                  />
                </div>
                <div v-if="item.conversationType === '3'">
                  <span
                    :class="
                      item.sender.userEmail === email
                        ? 'img_head_dark'
                        : 'img_head'
                    "
                  >
                    {{ item.sender.userName.substring(0, 1) }}</span
                  >
                  <video
                    ref="myVideo"
                    :poster="poster"
                    :src="item.videoUrl"
                    :controls="controls"
                    oncontextmenu="return false"
                    @timeupdate="timeupdate"
                    controlslist="nodownload"
                    class="video-box"
                  />
                </div>
                <div v-if="item.conversationType === '5'">
                  <span class="img_head_dark">{{
                    item.sender.userName.substring(0, 1)
                  }}</span>
                  <div class="msg_bg">
                    <div style="color: #a1afc6; display: inline-block">
                      请对本次服务评价
                    </div>
                    <br />
                    <div
                      style="
                        margin-top: 5px;
                        color: #333333;
                        font-size: 13px;
                        display: inline-block;
                      "
                    >
                      客服朵朵是否解决了您的问题？
                    </div>
                    <div
                      style="margin-top: 10px"
                      v-if="item.conversationContent === ''"
                    >
                      <el-button style="cursor: text">未解决</el-button>
                      <el-button
                        style="
                          background-color: #4aa3ff;
                          color: white;
                          margin-left: 30px;
                          cursor: text;
                        "
                        >已解决</el-button
                      >
                    </div>
                    <div
                      class="item_evaluate"
                      v-if="item.conversationContent === '1'"
                    >
                      <img
                        class="img_evaluate"
                        src="@/assets/image/online_bq_smile.png"
                      />
                      <span class="item_span_text">已解决</span>
                    </div>
                    <div
                      class="item_evaluate"
                      v-if="item.conversationContent === '2'"
                    >
                      <img
                        class="img_evaluate"
                        src="@/assets/image/online_bq_sad.png"
                      />
                      <span class="text_evaluate_refuse">未解决</span>
                    </div>
                  </div>
                </div>
                <div v-if="item.conversationType === '6'">
                  <span class="img_head_dark">{{
                    item.sender.userName.substring(0, 1)
                  }}</span>
                  <div class="msg_bg">
                    <div>请问可以获取您的报错日志吗？</div>
                    <div style="margin-top: 5px">
                      以便于我们更好的处理问题。
                    </div>
                    <div
                      style="margin-top: 10px"
                      v-if="item.conversationContent === ''"
                    >
                      <el-button style="cursor: text">不同意</el-button>
                      <el-button
                        style="
                          background-color: #4aa3ff;
                          color: white;
                          margin-left: 30px;
                          cursor: text;
                        "
                        >同意</el-button
                      >
                    </div>
                    <div
                      class="item_evaluate"
                      v-if="item.conversationContent === '1'"
                    >
                      <img
                        class="img_evaluate"
                        src="@/assets/image/online_bq_smile.png"
                      />
                      <span class="item_span_text">同意</span>
                    </div>
                    <div
                      class="item_evaluate"
                      v-if="item.conversationContent === '2'"
                    >
                      <img
                        class="img_evaluate"
                        src="@/assets/image/online_bq_sad.png"
                      />
                      <span class="text_evaluate_refuse">不同意</span>
                    </div>
                  </div>
                </div>
                <div v-if="item.conversationType === '4'">
                  <span
                    :class="
                      item.sender.userEmail === email
                        ? 'img_head_dark'
                        : 'img_head'
                    "
                    >{{ item.sender.userName.substring(0, 1) }}</span
                  >
                  <span
                    class="log_item"
                    @click="lookPic(item.conversationContent)"
                    >{{ getLogName(item.conversationContent) }}</span
                  >
                </div>
              </li>
            </ul>
          </div>

          <div style="width: auto; height: 1px; background-color: #cbe0fb" />
          <div
            class="operation"
            style="
              padding-top: 5px;
              padding-bottom: 5px;
              overflow: hidden;
              vertical-align: middle;
            "
          >
            <input
              v-show="false"
              ref="fileRef"
              id="file"
              name="file"
              type="file"
              multiple="multiple"
              @change="fileChange($event)"
            />
            <img
              src="~@/assets/image/icon_folder.png"
              @click="openFile"
              class="shoot_screen_icon"
            />
            <img
              src="~@/assets/image/icon_shoot_screen.png"
              @click="shootScreen"
              class="shoot_screen_icon"
            />
            <div style="float: right; margin-right: 10px">
              <el-button
                type="primary"
                v-if="selectType === 1"
                @click="getLog"
                plain
                >日志请求</el-button
              >
              <el-button
                type="primary"
                v-if="selectType === 1"
                @click="showCustomsDialog"
                plain
                >客服转接</el-button
              >
              <el-button
                type="primary"
                v-if="selectType === 1"
                @click="getEvaluate"
                plain
                >服务评价</el-button
              >
              <el-button type="primary" @click="sendMessage">发送</el-button>
            </div>
          </div>
          <div style="width: auto; height: 1px; background-color: #cbe0fb" />
          <div
            id="edit_msg"
            class="edit_msg"
            contenteditable="true"
            placeholder="请输入内容……"
            @keydown.enter="sendMessage"
          />
        </div>
      </div>
    </div>
    <!--客服转接-->
    <el-dialog title="客服转接" width="388px" :visible.sync="visible">
      <div style="padding: 20px 20px">
        系统自动获取当前在线客服：
        <div style="margin: 10px 10px">
          <el-radio-group v-model="radio1">
            <el-button
              type="primary"
              v-for="(item, i) in customers"
              :key="i"
              @click="clickItemTransfer(item)"
              plain
              >{{ item.userName }}
            </el-button>
          </el-radio-group>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="info" @click="visible = false">关闭</el-button>
          <el-button type="primary" @click="transferChat">确认</el-button>
        </span>
      </template>
    </el-dialog>
    <!--添加标签-->
    <el-dialog
      title="添加标签"
      :visible.sync="isShowAddTag"
      width="500px"
      @closed="dialogClosedAdd"
    >
      <div style="padding: 20px 20px; max-height: 400px; overflow-y: scroll">
        <span style="font-size: 13px; color: #333333"
          >请为当前会话选择以下标签？</span
        >
        <div
          style="margin-top: 10px"
          v-for="(item, position) in tag_list"
          :key="position"
        >
          <el-checkbox v-model="item.isCheck" @change="changeParent(item)">
            {{ item.labelName }}
          </el-checkbox>
          <div style="width: auto">
            <el-checkbox-button
              @change="changeChild(item, item1)"
              v-show="item.childLabels.length !== 0"
              v-for="(item1, i) in item.childLabels"
              :label="item1"
              v-model="item1.isCheck"
              :key="i"
              >{{ item1.labelName }}</el-checkbox-button
            >
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="info" @click="dialogClosedAdd">关闭</el-button>
          <el-button type="primary" @click="confirmAddTag">确认</el-button>
        </span>
      </template>
    </el-dialog>

    <!--下线弹窗-->
    <el-dialog title="下线确认" width="388px" :visible.sync="isOffline">
      <div style="padding: 20px 20px; font-size: 13px; color: #333333">
        请再次确认您的客服工作是否结束，确定下线您将结束当前在线客服工作！
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            style="background-color: #aaaaaa; color: #333333"
            @click="isOffline = false"
            >取消</el-button
          >
          <el-button
            style="background-color: #4aa3ff; color: white"
            @click="confirmOffline"
            >确认</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import kscreenshot from "kscreenshot";
//   import { getUserState, updateUserState, getChatList, commonList, getUserInfo, initChat, upload, transferList, transfer, closeChat, uploadBase64Img } from '@/api/customer'
//   import { getTagList, getLabelList, getSelectLabelList, addTags, deleteTag, updateTags, addTagMark, addTrackOrMark } from '@/api/customer/tag'
//   import { ElMessage, ElNotification } from 'element-plus'
import { checkRich } from "@/utils/regular";

export default {
  name: "Customer",
  data() {
    return {
      studyTime: {
        currentTime: 0, // 当前已学时长
        duration: 0, // 总时长
      },
      refreshing: false, // 是否正在上拉刷新
      client: null,
      todayTime: "",
      tag_mark: "",
      follow_mark: "",
      tab1: "正在接入 0",
      tab2: "待接入 0",
      tab3: "跟踪库 0",
      activeName: "first",
      activeServe: "服务标记",
      userCheck: "用户信息",
      state: 1,
      showChatInfo: false,
      isOffline: false,
      isLoading: false, // 下拉刷新
      loading: false, // 上拉加载
      finished: false, // 加载与不加载的状态
      tag_list: [],
      kscreenshot: new kscreenshot({
        key: 65,
        needDownload: false,
        endCB: function (e) {
          const edit = document.getElementById("edit_msg");
          const img = document.createElement("img");
          img.setAttribute("src", e);
          img.setAttribute("disabled", true);
          img.style.width = 80 + "px";
          img.style.height = 80 + "px";
          edit.appendChild(img);
        },
      }),
      tags: [],
      radio: "1",
      currentRadio: "1",
      tabPosition: "1",
      radio1: "用户信息",
      messageList: [],
      messageType: "text",
      messageText: "",
      runningChatList: [],
      followChatList: [],
      waitChatList: [],
      selectChat: {},
      selectType: 0,
      value: false,
      visible: false,
      isShowAddTag: false,
      selectDate: "",
      userDeviceList: [],
      usefulList: [],
      params: {
        searchType: "",
        page: 1,
        size: 10,
      },
      select_tags: [],
      isUpdate: false,
      common_search: "",
      userInfo: {},
      ws: null,
      timeout: 10 * 1000,
      isDestroyed: false, // 页面是否销毁
      lockReconnect: false, // 是否真正建立连接
      timeoutObj: null, // 心跳心跳倒计时
      serverTimeoutObj: null, // 心跳倒计时
      timeoutnum: null, // 断开 重连倒计时
      conversationCode: "",
      email: "",
      isStop: false,
      sender: {
        userName: "",
        userEmail: "",
      },
      getter: {
        userName: "",
        userEmail: "",
      },
      customers: [],
      transferParams: {
        conversationId: 0,
        userId: 0,
        userName: "",
        userEmail: "",
        beforeUserName: "",
      },
      src: "",
    };
  },

  props: {
    //   poster: {
    //     type: String,
    //     required: false,
    //     default: ''
    //   },
    //   src: {
    //     type: String,
    //     required: true
    //   },
    //   controls: {
    //     type: Boolean,
    //     required: false,
    //     default: true
    //   },
    //   control: {
    //     type: Boolean,
    //     required: false,
    //     default: false
    //   },
    //   videoData: {
    //     type: Object,
    //     required: true
    //   }
  },

  mounted() {
    const ran = navigator.userAgent;
    const isAndroid = ran.indexOf("Android") > -1 || ran.indexOf("Linux") > -1;
    const isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isAndroid || isIOS) {
      // 安卓代码块
    } else {
      window.addEventListener("load", function () {
        Notification.requestPermission(function (status) {
          // 这将使我们能在 Chrome/Safari 中使用 Notification.permission
          if (Notification.permission !== status) {
            Notification.permission = status;
          }
        });
      });
      window.Notification.requestPermission();
    }
    // alert("111111111111111111111111111")
    // window.addEventListener('load', function () {
    //   Notification.requestPermission(function (status) {
    //     // 这将使我们能在 Chrome/Safari 中使用 Notification.permission
    //     if (Notification.permission !== status) {
    //       Notification.permission = status
    //     }
    //   })
    // })
    // window.Notification.requestPermission()
    // setTimeout(() => {
    this.getUserStatus();
    this.getChattingList();
    this.getFollowList();
    this.getWaitList();
    // }, 500);

    // alert("22222222222222222222222222")
  },

  unmounted() {
    console.log("准备销毁页面");
    if (this.ws) {
      this.isStop = true;
      this.ws.close();
    }
  },
  methods: {
    getResolution(resolution) {
      return resolution === "" ? "-" : resolution;
    },
    clickClose() {
      if (this.selectType === 1) {
        this.$api
          .closeChat({
            conversationId: this.selectChat.conversationId,
          })
          .then((res) => {
            console.log("正在接入库：" + JSON.stringify(res));
            this.getChattingList();
          });
      } else if (this.selectType === 3) {
        console.log("------showChatInfo  clickClose------");
        this.showChatInfo = false;
        this.followChatList[0].isActive = false;
      }
    },
    handleClick() {
      console.log(this.activeName, "activeName=========`1234567");
      this.getChattingList();
      this.getWaitList();
      this.getFollowList();

      // if (this.activeName === 'first') {
      //   this.getChattingList()
      // } else if (this.activeName === 'second') {
      //   this.getWaitList()
      // } else if (this.activeName === 'second') {
      //   this.getFollowList()
      // }
    },
    // 获取当前播放位置
    timeupdate(e) {
      this.studyTime.currentTime = e.target.currentTime;
      this.studyTime.duration = e.target.duration ? e.target.duration : 0;
    },

    /**
     * 获取状态
     * */
    getUserStatus() {
      this.$api
        .getUserState()
        .then((res) => {
          this.radio = this.currentRadio = res.data.data.state;
          this.email = res.data.data.userEmail;
          this.todayTime = res.data.data.dates;
          this.sender.userEmail = res.data.data.userEmail;
          this.sender.userName = res.data.data.userName;
          sessionStorage.setItem("userEmail", res.data.data.userEmail);
          sessionStorage.setItem("userName", res.data.data.userName);
          if (this.radio === "1") {
            this.connectMQ("");
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            location.href = "/login";
          }
        });
    },

    /**
     * 更新状态
     * */
    update(type) {
      this.$api
        .updateUserState({ state: type })
        .then(() => {
          console.log("修改客服状态返回：");
          this.radio = this.currentRadio = type;
          this.showChatInfo =
            this.radio === "1" && this.runningChatList.length > 0;
          if (this.radio !== "1") {
            // console.log(this.ws, this.ws.readyState);
            if (this.ws.readyState === 1) {
              this.isStop = true;
              this.ws.close();
            }
          } else {
            this.connectMQ("");
          }
        })
        .catch((error) => {
          console.log("update", error);
        });
    },

    /**
     * 正在接入
     * */
    getChattingList() {
      this.params.searchType = "1";
      this.$api
        .getChatList(this.params)
        .then((res) => {
          this.runningChatList = [];
          if (res.data.data.length) {
            if (this.selectChat) {
              res.data.data.forEach((item) => {
                if (item.conversationId === this.selectChat.conversationId) {
                  this.runningChatList.push(item);
                }
              });
              const filterList = res.data.data.filter((it) => {
                return it.conversationId !== this.selectChat.conversationId;
              });
              filterList.forEach((item1) => {
                this.runningChatList.push(item1);
              });
            } else {
              this.runningChatList = res.data.data;
            }
          }
          if (this.runningChatList.length) {
            this.runningChatList.forEach((item) => {
              Object.assign(item, { isActive: false });
            });
          }
          this.tab1 = "正在接入 " + this.runningChatList.length;
          if (this.activeName === "first") {
            if (this.runningChatList.length === 0) {
              this.showChatInfo = false;
              console.log("------showChatInfo  first------");
            } else {
              if (this.radio === "1") {
                this.showChatInfo = true;
              }
              if (this.runningChatList.length) {
                this.runningChatList[0].hasNew = 0;
                this.selectChat = this.runningChatList[0];
              }
              this.selectType = 1;
              this.selectChat.isActive = true;
              this.getter.userEmail = this.selectChat.userEmail;
              this.getter.userName = this.selectChat.userName;
              this.getUserInfos();
            }
          } else if (this.activeName === "third") {
            if (this.followChatList.length === 0) {
              this.showChatInfo = false;
              console.log("------showChatInfo  third------");
            } else {
              if (this.radio === "1") {
                this.showChatInfo = true;
              }
              if (this.followChatList.length) {
                this.followChatList[0].hasNew = 0;
                this.selectChat = this.followChatList[0];
              }
              this.selectChat.isActive = true;
              this.selectType = 3;
              this.getter.userEmail = this.selectChat.userEmail;
              this.getter.userName = this.selectChat.userName;
              this.getUserInfos();
            }
          }
        })
        .catch((error) => {
          console.log(error, "333333333333");
          if (error.response.status == 401) {
            location.href = "/login";
          }
        });
    },

    /**
     * 跟踪库
     * */
    getFollowList() {
      this.params.searchType = "3";
      this.$api
        .getChatList(this.params)
        .then((res) => {
          this.followChatList = [];
          if (res.data.data.length) {
            if (this.selectChat) {
              res.data.data.forEach((item) => {
                if (item.conversationId === this.selectChat.conversationId) {
                  this.followChatList.push(item);
                }
              });
              const filterList = res.data.data.filter((it) => {
                return it.conversationId !== this.selectChat.conversationId;
              });
              filterList.forEach((item1) => {
                this.followChatList.push(item1);
              });
            } else {
              this.followChatList = res.data.data;
            }
          }
          if (this.followChatList.length) {
            this.tab3 = "跟踪库 " + this.followChatList.length;
          }
          if (this.followChatList.length === 0 && this.activeName === "third") {
            this.showChatInfo = false;
            console.log("------showChatInfo  跟踪库------");
          } else {
            this.followChatList.forEach((item) => {
              if (item.conversationId === this.selectChat.conversationId) {
                Object.assign(item, { isActive: true });
              } else {
                Object.assign(item, { isActive: false });
              }
            });
          }
        })
        .catch(() => {
          // alert("111111111");
        });
    },

    /**
     * 待接入
     * */
    getWaitList() {
      this.params.searchType = "2";
      this.$api
        .getChatList(this.params)
        .then((res) => {
          // console.log('待接入列表：'+JSON.stringify(res)+","+res.data.data)
          // if (res.data.data.length) {
          this.waitChatList = res.data.data;
          // }
          this.tab2 = "待接入 " + this.waitChatList.length;
          if (this.waitChatList.length > 0) {
            this.waitChatList.forEach((item) => {
              Object.assign(item, { isActive: false });
            });
          }
        })
        .catch((error) => {
          alert(error, "22222222");
        });
    },

    clickItem(item) {
      // console.log('点击会话'+JSON.stringify(item))
      if (this.radio === "1") {
        const list = [];
        list.push(item);
        if (this.runningChatList.length) {
          const filterList = this.runningChatList.filter((it) => {
            return it.conversationId !== item.conversationId;
          });
          filterList.forEach((item1) => {
            list.push(item1);
          });
        }

        this.runningChatList = list;
        if (this.runningChatList.length) {
          this.runningChatList.forEach((it) => {
            it.isActive = it.conversationId === item.conversationId;
          });
        }
        if (this.followChatList.length) {
          this.followChatList.forEach((it) => {
            it.isActive = false;
          });
        }

        if (!this.showChatInfo) {
          this.showChatInfo = true;
        }
        // console.log("重组后的数组为："+JSON.stringify(list))
        item.hasNew = 0;
        this.selectChat = item;
        this.selectType = 1;
        this.getter.userName = item.userName;
        this.getter.userEmail = item.userEmail;
        this.getUserInfos();
      }
    },

    clickFollowItem(item) {
      // console.log('点击追踪会话：'+JSON.stringify(item))
      if (this.radio === "1") {
        const list = [];
        list.push(item);
        const filterList = this.followChatList.filter((it) => {
          return it.conversationId !== item.conversationId;
        });
        filterList.forEach((item1) => {
          list.push(item1);
        });
        this.followChatList = list;
        this.followChatList.forEach((it) => {
          it.isActive = it.conversationId === item.conversationId;
        });
        this.runningChatList.forEach((it) => {
          it.isActive = false;
        });
        this.showChatInfo = true;
        this.selectChat = item;
        this.getter.userName = item.userName;
        this.getter.userEmail = item.userEmail;
        this.selectType = 3;
        this.getUserInfos();
      }
    },

    getUserInfos() {
      this.getShowTagList();
      this.allTagList();
      this.getCommonList();
      this.getUserBaseInfo();
      this.initChatInfo();
    },

    /**
     * 初始化会话信息
     * */
    initChatInfo() {
      this.$api
        .initChat({
          conversationId: this.selectChat.conversationId,
        })
        .then((res) => {
          this.conversationCode = res.data.data.conversationCode;
          if (res.data.data.history.length) {
            this.messageList = res.data.data.history;
            this.messageList.forEach((item) => {
              if (item.conversationType === "2") {
                if (item.conversationContent.includes("?")) {
                  const array = item.conversationContent.split("?");
                  Object.assign(item, { imgUrl: array[0] });
                  const a = array[1].split("*");
                  Object.assign(item, {
                    isVertical: parseFloat(a[0]) / parseFloat(a[1]) > 1,
                  });
                } else {
                  Object.assign(item, { imgUrl: item.conversationContent });
                  Object.assign(item, { isVertical: false });
                }
              } else if (item.conversationType === "3") {
                if (item.conversationContent.includes("?")) {
                  const array = item.conversationContent.split("?");
                  Object.assign(item, { videoUrl: array[0] });
                } else {
                  Object.assign(item, { videoUrl: item.conversationContent });
                }
              }
            });
            // console.log("会话信息为：", this.messageList);
          }

          if (this.showChatInfo) {
            this.$nextTick(() => {
              const chat = document.getElementById("chat"); // 获取对象
              chat.scrollTop = chat.scrollHeight; // 滚动高度
            });
          }
        });
    },

    getUserBaseInfo() {
      this.$api
        .getUserInfo({
          userEmail: this.selectChat.userEmail,
        })
        .then((res) => {
          // console.log('获取用户信息为：'+JSON.stringify(res))
          this.userInfo = res.data.data.user;
          this.userDeviceList = res.data.data.system;
          if (this.userDeviceList && this.userDeviceList.length > 0) {
            this.userDeviceList.forEach((item) => {
              Object.assign(item, { show: false });
            });
          }
        });
    },
    getUserName(name) {
      return name === "" ? "-" : name;
    },
    getVipType(type) {
      return type === "" ? "-" : type;
    },

    /**
     * 获取常用语
     * */
    getCommonList() {
      this.$api.commonList({ content: this.common_search }).then((res) => {
        this.usefulList = res.data.data.filter((item) => {
          return item.commonly.length > 0;
        });
        if (this.usefulList.length > 0) {
          this.usefulList.forEach((item) =>
            Object.assign(item, { show: false })
          );
        }
      });
    },

    /**
     * 点击常用语
     * */
    clickCommon(tag) {
      const edit = document.getElementById("edit_msg");
      edit.innerHTML += tag;
    },

    /**
     * 获取显示标签
     * */
    getShowTagList() {
      this.$api
        .getTagList({ conversationId: this.selectChat.conversationId })
        .then((res) => {
          // console.log("标签返回："+JSON.stringify(res))
          this.tags = res.data.data.labels;
          this.follow_mark = res.data.data.track.trackRemark;
          this.tag_mark = res.data.data.remark;
          this.value = res.data.data.track.isTrack === 1;
        });
    },

    /**
     * 全部标签
     * */
    allTagList() {
      this.$api.getLabelList().then((res) => {
        this.tag_list = res.data.data;
        this.tag_list.forEach((item) => {
          Object.assign(item, { isCheck: false });
          if (item.childLabels !== null && item.childLabels.length > 0) {
            item.childLabels.forEach((item1) => {
              Object.assign(item1, { isCheck: false });
            });
          }
        });
      });
    },

    /**
     * 删除标签
     * */
    handleClose(tag) {
      this.$api
        .deleteTag({
          conversationId: this.selectChat.conversationId,
          labelId: tag.labelId,
        })
        .then(() => {
          // console.log('删除标签返回：'+JSON.stringify(res))
          this.tags = this.tags.filter((item) => {
            return item.labelId !== tag.labelId;
          });
          this.getChattingList();
        });
    },

    /**
     * 添加服务标记备注
     *
     * */
    clickAddMark() {
      this.$api
        .addTagMark({
          conversationId: this.selectChat.conversationId,
          remark: this.tag_mark,
        })
        .then((res) => {
          // console.log("添加服务备注："+JSON.stringify(res))
          if (res.code === 0) {
            this.showTip("添加服务备注成功");
          } else {
            this.showTip("添加服务备注失败");
          }
        });
    },

    showTip(info) {
      // ElMessage({
      //   message: info,
      //   type: 'warning',
      //   duration: 3000
      // })
      this.$notify({
        title: "warning",
        message: info,
        type: "warning",
      });
    },

    showErrorTip(info) {
      // ElMessage({
      //   message: info,
      //   type: 'error',
      //   duration: 3000
      // })
      this.$notify({
        title: "失败",
        message: info,
        type: "error",
      });
    },

    changeFollowStatus() {
      let state = 0;
      if (this.value) {
        state = 1;
      } else {
        state = 0;
      }
      this.$api
        .addTrackOrMark({
          conversationId: this.selectChat.conversationId,
          trackState: state,
          trackRemark: this.follow_mark,
        })
        .then((res) => {
          // console.log("追踪标记返回："+JSON.stringify(res))
          if (res.code === 0) {
            this.showTip("成功");
            this.getFollowList();
          } else {
            this.showTip("失败");
          }
        });
    },

    getUserConnectState(state) {
      if (state === 1) {
        return "用户在线";
      } else {
        return "用户离线";
      }
    },

    getChatInfoFormat(chatInfo) {
      if (chatInfo.conversationType === "5") {
        return "[评价]";
      } else if (chatInfo.conversationType === "6") {
        return "[日志]";
      } else if (chatInfo.conversationType === "2") {
        return "[图片]";
      } else if (chatInfo.conversationType === "3") {
        return "[视频]";
      } else if (chatInfo.conversationType === "4") {
        return "[文件]";
      } else {
        return chatInfo.newMessage;
      }
    },

    openFile() {
      this.$refs.fileRef.dispatchEvent(new MouseEvent("click")); // 弹出选择本地文件
    },

    /**
     * 选择文件返回
     * */
    fileChange() {
      const fileObj = document.getElementById("file");
      const file = fileObj.files[0];
      const formData = new FormData();
      formData.append("files", file, file.name);
      const type = this.getMsgType(file.name.toLowerCase());

      /* if (type === "2"){
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function (evt) {
            let replaceSrc = evt.target.result;
            let imageObj = new Image();
            imageObj.src = replaceSrc;
            imageObj.onload = function () {
              console.log('图片的宽高：'+imageObj.width +'*'+ imageObj.height);
            };
          };
        }else if (type === '3'){
          new Promise(function (resolve, reject) {
            let videoUrl = URL.createObjectURL(file);
            let videoObj = document.createElement("video");
            videoObj.onloadedmetadata = function (evt) {
              URL.revokeObjectURL(videoUrl);
              console.log('视频的宽高：'+videoObj.videoWidth +"*"+ videoObj.videoHeight);
            };
            videoObj.src = videoUrl;
          });
        }else{
          this.showTip("请选择图片或者视频文件")
        }*/
      if (type !== "4") {
        this.$api.upload(formData).then((res) => {
          // console.log("发送文件的返回：" + JSON.stringify(res));
          const data = {
            conversationId: this.selectChat.conversationId,
            sendTime: new Date().getTime(),
            conversationContent: res.data.data.url,
            conversationType: type,
            sender: this.sender,
            getter: this.getter,
            selectType: this.selectType,
          };
          this.ws.send(JSON.stringify(data));
          this.messageList.push(data);
          this.getChattingList();
          this.$refs.fileRef.value = "";
        });
      } else {
        this.showTip("请选择图片或者视频文件");
      }
    },
    getMsgType(name) {
      if (
        name.includes(".jpg") ||
        name.includes(".png") ||
        name.includes(".gif") ||
        name.includes(".jpeg") ||
        name.includes(".bmp")
      ) {
        return "2";
      } else if (
        name.includes(".mp4") ||
        name.includes(".mov") ||
        name.includes(".avi") ||
        name.includes(".flv") ||
        name.includes(".wmv") ||
        name.includes(".m2v") ||
        name.includes(".mkv")
      ) {
        return "3";
      } else {
        return "4";
      }
    },
    isShow(item, position) {
      if (position !== 0) {
        if (item.sender.userEmail === this.sender.userEmail) {
          return (
            item.sendTime - this.messageList[position - 1].sendTime >
            3 * 60 * 1000
          );
        } else {
          return (
            item.sendTime - this.messageList[position - 1].sendTime >
            6 * 60 * 1000
          );
        }
      }
      return true;
    },
    getSendTime(mills) {
      const time = new Date(mills);
      const year = time.getFullYear(),
        month = time.getMonth() + 1, // 月份是从0开始的
        day = time.getDate(),
        hour = time.getHours(),
        minute = time.getMinutes(),
        second = time.getSeconds();
      // add0()方法在后面定义
      return (
        year +
        "-" +
        this.add0(month) +
        "-" +
        this.add0(day) +
        "  " +
        this.add0(hour) +
        ":" +
        this.add0(minute) +
        ":" +
        this.add0(second)
      );
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },

    /**
     * 查看 大图
     * */
    lookPic(img) {
      window.open(img, "_blank");
    },
    getObjectURL(file) {
      let url = null;
      if (window.createObjectURL !== undefined) {
        // basic
        url = window.createObjectURL(file);
      } else if (window.webkitURL !== undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      } else if (window.URL !== undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      }
      return url;
    },

    /**
     * 截屏
     * */
    shootScreen() {
      this.kscreenshot.startScreenShot();
    },

    async showCustomsDialog() {
      this.visible = true;
      const customer = await this.$api.transferList();
      this.customers = customer.data;
    },

    /**
     * 客服转接弹窗
     * */
    clickItemTransfer(info) {
      this.transferParams.conversationId = this.selectChat.conversationId;
      this.transferParams.userId = info.userId;
      this.transferParams.userEmail = info.userEmail;
      this.transferParams.userName = info.userName;
      this.transferParams.beforeUserName = this.sender.userName;
    },

    /**
     * 确认转接
     * */
    transferChat() {
      if (this.transferParams.userId > 0) {
        this.$api.transfer(this.transferParams).then(() => {
          this.visible = false;
          this.transferParams.conversationId = 0;
          this.transferParams.userId = 0;
          this.transferParams.userEmail = "";
          this.transferParams.userName = "";
          this.transferParams.beforeUserName = "";
          this.getChattingList();
        });
      } else {
        this.showTip("请您选择要转接的客服人员");
      }
    },

    /**
     * 添加标签弹窗
     * */
    showAddTag() {
      this.isShowAddTag = true;
      this.$api
        .getSelectLabelList({ conversationId: this.selectChat.conversationId })
        .then((res) => {
          this.select_tags = res.data.data;
          if (this.select_tags.length !== null && this.select_tags.length > 0) {
            this.isUpdate = true;
            this.tag_list.forEach((tag) => {
              this.select_tags.forEach((item) => {
                if (item.parentId === tag.labelId) {
                  if (tag.childLabels !== null && tag.childLabels.length > 0) {
                    tag.childLabels.forEach((child) => {
                      if (child.labelId === item.childId) {
                        child.isCheck = true;
                      }
                    });
                  } else {
                    tag.isCheck = true;
                  }
                }
              });
            });
          }
          // console.log("选中的标签为："+JSON.stringify(this.tag_list))
        });
    },

    /**
     * 选择一级标签
     * */
    changeParent(item) {
      // console.log("父标签"+JSON.stringify(item))
      item.childLabels.forEach((child) => {
        child.isCheck = item.isCheck;
        if (item.isCheck) {
          this.select_tags.push({
            parentId: item.labelId,
            childId: child.labelId,
          });
        } else {
          this.select_tags = this.select_tags.filter((itemTag) => {
            return itemTag.parentId !== item.labelId;
          });
        }
      });
    },

    /**
     * 选择二级标签
     * */
    changeChild(parent, child) {
      // console.log("选中子标签：父标签"+JSON.stringify(parent)+",子标签"+JSON.stringify(child))
      const parentNum = parent.childLabels.length;
      let i = 0;
      parent.childLabels.forEach((child) => {
        if (child.isCheck) {
          i += 1;
        }
      });
      parent.isCheck = parentNum === i;
      if (child.isCheck) {
        this.select_tags.push({
          parentId: parent.labelId,
          childId: child.labelId,
        });
      } else {
        this.select_tags = this.select_tags.filter((itemTag) => {
          return itemTag.childId !== child.labelId;
        });
      }
      // console.log('选择标签的数组为：'+JSON.stringify(this.select_tags))
    },

    confirmAddTag() {
      if (this.isUpdate) {
        this.$api
          .updateTags({
            conversationId: this.selectChat.conversationId,
            labelsIds: this.select_tags,
          })
          .then(() => {
            this.isShowAddTag = false;
            this.getShowTagList();
            this.getChattingList();
            // console.log("修改标签的返回："+JSON.stringify(res))
          });
      } else {
        this.$api
          .addTags({
            conversationId: this.selectChat.conversationId,
            labelsIds: this.select_tags,
          })
          .then(() => {
            this.isShowAddTag = false;
            this.getShowTagList();
            this.getChattingList();
            // console.log("添加标签的返回："+JSON.stringify(res))
          });
      }
    },
    dialogClosedAdd() {
      console.log("关闭添加标签弹窗");
      this.isShowAddTag = false;
      this.select_tags = [];
    },
    getLogName(info) {
      const splits = info.split("/");
      console.log("获取的文件数组为：" + splits);
      return splits[splits.length - 1];
    },
    getCustomerState() {
      if (this.radio === "3") {
        return "您处于“下线”状态";
      } else if (this.radio === "2") {
        return "您处于“离开”状态";
      } else if (this.radio === "1") {
        return "无选中对话";
      }
    },
    getStateInfo() {
      if (this.radio === "3") {
        return "点击“上线”开启客服工作，微笑面对每一位用户！";
      } else if (this.radio === "2") {
        return "点击“上线”继续客服工作，坚持就是胜利！";
      } else if (this.radio === "1") {
        return "没有需要处理的对话，休息一下吧！";
      }
    },
    online() {
      this.update("1");
    },
    leave() {
      this.update("2");
    },
    offline() {
      if (this.runningChatList.length > 0) {
        this.radio = this.currentRadio;
        this.showErrorTip(
          "当前“正在接入库”还有未完结的服务工单，处理完毕才可“下线”"
        );
      } else {
        this.isOffline = true;
        this.radio = this.currentRadio;
      }
    },
    confirmOffline() {
      this.isOffline = false;
      this.update("3");
    },
    connectMQ: function (info) {
      console.log("当前的连接为：" + info);
      console.log(this.VUE_APP_WS_CUSTOMER_URL, "-------------------");
      this.isStop = false;
      this.ws = new WebSocket(
        this.VUE_APP_WS_CUSTOMER_URL +
          "?email=" +
          this.email +
          "&reback=" +
          info
      );
      if (window.WebSocket) {
        this.ws.onopen = () => {
          console.log("连接websocket");
          this.startHeart();
        };
        this.ws.onclose = () => {
          console.log("服务器连接关闭");
          if (!this.isStop && localStorage.login === "1") {
            this.reconnect(); // 重连
          }
        };
        this.ws.onerror = (e) => {
          console.log("服务器连接出错:" + JSON.stringify(e));
          this.reconnect(); // 重连
        };
        this.ws.onmessage = (e) => {
          // console.log('接收到的信息为：' + JSON.stringify(e.data))
          if (e.data !== "pong") {
            console.log("当前会话");
            const res = JSON.parse(e.data);
            console.log("当前会话信息：" + JSON.stringify(res));
            if (
              this.selectChat.conversationId === res.conversationId &&
              res.showState !== 1
            ) {
              this.messageList.push(res);
              console.log("消息列表为：" + JSON.stringify(this.messageList));
            } else {
              console.log("非当前会话");
            }
            if (res.conversationType === "16") {
              this.showTip(res.conversationContent);
            }
            if (
              res.conversationId !== 0 &&
              res.sender.userEmail !== this.sender.userEmail
            ) {
              this.getChattingList();
              this.getFollowList();
              this.getWaitList();
              if (document.hidden) {
                this.showNotification(res);
              }
            }
          }
          this.reset(); // 心跳重置
        };
      }
    },
    showNotification(info) {
      if (window.Notification.permission === "granted") {
        // 判断是否有权限
        console.log("---------notification 1------");
        this.sendNotification(info);
      } else if (window.Notification.permission !== "denied") {
        window.Notification.requestPermission(function () {
          // 没有权限发起请求
          console.log("---------notification 2------");
          this.sendNotification(info);
        });
      }
    },
    sendNotification(info) {
      new Notification("在线客服", {
        body: this.getNotificationMsg(info),
        icon: require("@/assets/image/appicon.png"),
      });
    },
    getNotificationMsg(chatInfo) {
      if (chatInfo.conversationType === "6") {
        return "[日志]";
      } else if (chatInfo.conversationType === "2") {
        return "[图片]";
      } else if (chatInfo.conversationType === "3") {
        return "[视频]";
      } else if (chatInfo.conversationType === "4") {
        return "[文件]";
      } else {
        return chatInfo.conversationContent;
      }
    },
    // 重新连接
    reconnect() {
      const self = this;
      if (self.lockReconnect || self.isDestroyed) {
        return;
      }
      console.log("重新连接。。。");
      self.lockReconnect = true;
      // 没连接上会一直重连，间隔5秒连接一次
      self.timeoutnum && clearTimeout(self.timeoutnum);
      self.timeoutnum = setTimeout(function () {
        // 新连接
        self.connectMQ("reback");
        self.lockReconnect = false;
      }, 5000);
    },
    // 重置心跳
    reset() {
      const self = this;
      // 清除时间
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      // 重启心
      self.startHeart();
    },
    startHeart() {
      const self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setTimeout(() => {
        // console.log('连接状态：'+self.ws.readyState )
        if (localStorage.login === "1") {
          if (self.ws.readyState === 1) {
            // 判断连接是否正常
            self.ws.send("ping");
          } else {
            // 否则重连
            if (!this.isStop) {
              self.reconnect();
            }
          }
        }
        self.serverTimeoutObj = setTimeout(function () {
          console.log("心跳关闭socket");
          self.ws.close(); // 超过3秒没收到心跳消息关闭重连
        }, 10000);
      }, this.timeout);
    },

    /**
     * 获取日志
     * */
    getLog() {
      const data = {
        conversationId: this.selectChat.conversationId,
        sendTime: new Date().getTime(),
        conversationContent: "",
        conversationType: "6",
        sender: this.sender,
        getter: this.getter,
        selectType: this.selectType,
      };
      this.messageList.push(data);
      this.ws.send(JSON.stringify(data));
      this.getChattingList();
    },

    /**
     * 服务评价
     * */
    getEvaluate() {
      const data = {
        conversationId: this.selectChat.conversationId,
        sendTime: new Date().getTime(),
        conversationContent: "",
        conversationType: "5",
        sender: this.sender,
        getter: this.getter,
        selectType: this.selectType,
      };
      this.messageList.push(data);
      this.ws.send(JSON.stringify(data));
      this.getChattingList();
    },

    /**
     * 消息发送
     * */
    sendMessage(e) {
      const edit = document.getElementById("edit_msg");
      if (e.shiftKey && e.keyCode === 13) {
        edit.innerHTML += "";
      } else {
        const reg = /<img [^>]*src=['"]([^'"]+)[^>]*>/g;
        if (reg.test(edit.innerHTML)) {
          console.log("包含img标签");
          const data = [];
          edit.innerHTML.replace(reg, function (match, capture) {
            data.push(capture);
          });
          // console.log('获取到的图片信息为：'+data);
          data.forEach((item) => {
            this.sendBase64Img(item);
          });
          edit.innerText = edit.innerText.replace(/^\s+|\s+$/g, "");
          if (checkRich(edit.innerText) !== "") {
            this.sendText(edit.innerText);
          }
        } else {
          console.log("不包含img标签");
          edit.innerText = edit.innerText.replace(/^\s+|\s+$/g, "");
          if (checkRich(edit.innerText) !== "") {
            this.sendText(edit.innerText);
          } else {
            this.showTip("请输入要发送的消息内容");
          }
        }
        edit.innerHTML = "";
      }
    },
    sendBase64Img(base64Img) {
      this.$api
        .uploadBase64Img({
          baseImg: base64Img,
        })
        .then((res) => {
          const data = {
            conversationId: this.selectChat.conversationId,
            sendTime: new Date().getTime(),
            conversationContent: res.data.data.url,
            conversationType: "2",
            sender: this.sender,
            getter: this.getter,
            selectType: this.selectType,
          };
          this.ws.send(JSON.stringify(data));
          this.messageList.push(data);
          this.getChattingList();
        });
    },
    sendText(text) {
      const data = {
        conversationId: this.selectChat.conversationId,
        sendTime: new Date().getTime(),
        conversationContent: text,
        conversationType: "1",
        sender: this.sender,
        getter: this.getter,
        selectType: this.selectType,
      };
      // console.log("发送的消息为："+JSON.stringify(data))
      this.messageList.push(data);
      this.ws.send(JSON.stringify(data));
      this.getChattingList();
    },
  },
};
</script>

<style scoped>
.video-box {
  box-sizing: border-box;
  border: 0;
  display: inline-block;
  margin-left: 20px;
  max-width: 240px;
  max-height: 320px;
  outline: none !important;
  vertical-align: top;
}

.outside {
  width: 100%;
  position: relative;
  text-align: left;
}
.doge {
  width: auto;
  height: 20px;
  text-align: center;
}

.time_title {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* margin-top: -30px; */
}
.customer_time {
  background-color: #effaff;
  font-size: 13px;
  color: #4aa3ff;
  border-radius: 20px;
  padding: 8px 8px;
  line-height: 15px;
}
.left {
  float: left;
  width: 304px;
  margin-bottom: 10px;
  margin-left: 20px;
  border: 1px solid #cbe0fb;
  background-color: white;
}
.left_tab {
  min-height: 940px;
  max-height: 840px;
  overflow-y: auto;
}
.outerBox {
  padding: 10px 10px;
  overflow: hidden;
}

.right {
  float: right;
  top: 0;
  padding-bottom: 20px;
  width: 304px;
  margin-right: 20px;
}
.right_layout {
  width: 100%;
  border: 1px solid #cbe0fb;
  background-color: white;
}
.right_input {
  width: 220px;
  border: 1px solid #cbe0fb;
  border-radius: 5px;
  padding: 8px 8px;
  font-size: 13px;
  outline: none;
}
.right_search_text {
  margin-left: 10px;
  background-color: #cbe0fb;
  padding: 5px 10px;
  font-size: 14px;
  color: #222222;
  border-radius: 3px;
}

.add_tag {
  padding: 5px 10px;
  background-color: #4aa3ff;
  color: white;
  margin-left: 10px;
  text-align: center;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
}

.middle {
  width: auto;
  min-width: 500px;
  position: relative;
  margin-left: 340px;
  margin-right: 340px;
  border: 1px solid #cbe0fb;
  overflow: hidden;
  background-color: white;
}
.edit_msg {
  width: auto;
  height: 150px;
  padding: 10px 10px;
  word-break: break-all;
  overflow-y: scroll;
}
.edit_msg:empty:before {
  content: attr(placeholder);
  color: #ddd;
}
.edit_msg:focus:before {
  content: none;
}
.edit_msg:focus {
  border: none;
  outline: none;
}
.item_top {
  margin-top: 8px;
}
.item_text {
  display: inline-block;
  font-size: 13px;
  color: #333333;
}
.item_msg_text {
  display: inline-block;
  font-size: 12px;
  color: #333333;
}
.text_style {
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: inline-block;
  word-break: break-all;
  -webkit-box-orient: vertical;
}
.text_follow_style {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  word-break: break-all;
  -webkit-box-orient: vertical;
}
.log_item {
  display: inline-block;
  padding: 10px 10px;
  margin-left: 10px;
  background-color: #effaff;
  border-radius: 5px;
  vertical-align: top;
  color: #4aa3ff;
  text-decoration: underline;
  cursor: pointer;
}
.item_text_gray {
  font-size: 12px;
  color: #aaaaaa;
}
.item_transferFrom {
  float: right;
  font-size: 12px;
  color: #4aa3ff;
  margin-right: 20px;
}

.item_span_text {
  color: #50b54d;
  margin-left: 25px;
  text-align: center;
  position: absolute;
}

.item_evaluate {
  padding-left: 80px;
  height: 20px;
  margin-top: 10px;
  position: relative;
  line-height: 20px;
}
.img_evaluate {
  width: 20px;
  height: 20px;
  position: absolute;
}

.text_evaluate_refuse {
  color: #d8453e;
  margin-left: 25px;
  vertical-align: center;
}

.common {
  height: 40px;
  font-size: 13px;
  padding-left: 10px;
  color: white;
  text-align: center;
  margin: auto;
  line-height: 40px;
  background-color: rgba(31, 65, 113, 1);
}
.common_text {
  margin-top: 10px;
  color: #333333;
  font-size: 13px;
  margin-left: 10px;
  margin-right: 10px;
  word-break: break-all;
  word-wrap: break-word;
}
.user_info_text {
  display: inline-block;
  font-size: 13px;
  cursor: default;
  color: #333333;
  margin-top: 8px;
}
.user_device_text {
  width: 100%;
  font-size: 13px;
  cursor: default;
  color: #333333;
  margin-top: 8px;
}
.list {
  width: 100%;
  height: 700px;
  position: relative;
  overflow: auto;
  padding: 10px 10px;
}
.item {
  padding: 5px 5px;
}
.chat_time {
  text-align: center;
  color: #333333;
  margin-bottom: 10px;
  margin-top: 10px;
}
.img_head {
  width: 40px;
  height: 40px;
  display: inline-block;
  color: white;
  line-height: 40px;
  background-color: #4aa3ff;
  border-radius: 40px;
  font-size: 18px;
  text-align: center;
}
.img_head_dark {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  color: white;
  background-color: rgba(31, 65, 113, 1);
  border-radius: 40px;
  font-size: 18px;
  text-align: center;
}
.img_msg {
  padding: 10px 10px;
  margin-left: 10px;
  background-color: #f5f6ff;
  border-radius: 5px;
  vertical-align: top;
}
.img_msg_gray {
  padding: 10px 10px;
  margin-left: 10px;
  background-color: #effaff;
  border-radius: 5px;
  vertical-align: top;
}
.msg_bg {
  display: inline-block;
  padding: 10px 10px;
  margin-left: 10px;
  max-width: 500px;
  background-color: #f5f6ff;
  border-radius: 5px;
  vertical-align: top;
  word-break: break-all;
  white-space: pre-line;
}
.msg_bg_gray {
  display: inline-block;
  padding: 10px 10px;
  max-width: 500px;
  margin-left: 10px;
  background-color: #effaff;
  border-radius: 5px;
  vertical-align: top;
  word-break: break-all;
  white-space: pre-line;
}
.text_tab {
  margin-left: 10px;
}

.shoot_screen_icon {
  width: 30px;
  height: 30px;
  margin-left: 15px;
}

.mark {
  width: 100%;
  height: 100px;
  font-size: 13px;
  margin-top: 15px;
  padding: 10px 10px;
  border-radius: 3px;
  border: solid 0.5px #cbe0fb;
  outline: none;
  resize: none;
}

::v-deep .el-radio-group {
  font-size: 0;
  display: inline-block;
  width: 100%;
}

::v-deep .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  font-size: inherit;
  font-family: inherit;
  color: var(--el-input-font-color, var(--el-text-color-regular));
  background-color: var(--el-input-background-color, var(--el-color-white));
  background-image: none;
  border: var(--el-input-border, var(--el-border-base));
  border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
  transition: var(--el-transition-border);
}

::v-deep .el-tabs__nav-scroll {
  background-color: #f0f2ff;
}

::v-deep .el-tabs__item {
  width: 100px;
  padding: 0 20px;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: var(--el-text-color-primary);
  position: relative;
  background-color: #f0f2ff;
}

::v-deep .el-tabs__item.is-active {
  background-color: rgba(31, 65, 113, 1);
  color: white;
}
::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0;
}
::v-deep .el-dialog__header {
  padding: 15px 10px 10px 10px;
}
::v-deep .el-dialog__body {
  padding: 0 0;
}
::v-deep .el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 10px 0;
}

::v-deep .el-dialog__title {
  font-size: 18px;
  color: #000000;
}

/**修改el-checkbox-button样式*/
::v-deep .el-checkbox-button__inner {
  width: 100px;
  display: inline-block;
  line-height: 1;
  font-weight: var(--el-checkbox-font-weight);
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background: var(--el-button-background-color, var(--el-color-white));
  border: 1px solid #dcdfe6;
  color: var(--el-button-font-color, var(--el-text-color-regular));
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 10px 10px;
  position: relative;
  transition: var(--el-transition-all);
  -webkit-user-select: none;
  user-select: none;
  padding: 8px 8px;
  font-size: var(--el-font-size-base, 14px);
  border-radius: 20px;
}
::v-deep .el-checkbox-button:last-child .el-checkbox-button__inner {
  border-left: 1px solid #dcdfe6;
  border-radius: 20px;
  box-shadow: none !important;
}
::v-deep .el-checkbox-button:first-child .el-checkbox-button__inner {
  border-left: 1px solid #dcdfe6;
  border-radius: 20px;
  box-shadow: none !important;
}

::v-deep .el-radio-button__inner {
  width: 151px;
  font-size: 13px;
  text-align: center;
  background-color: #f0f2ff;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #dcdfe6;
  border-radius: 0;
  box-shadow: none !important;
}
::v-deep .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0;
}
::v-deep .el-radio-button {
  --el-radio-button-checked-background-color: rgba(31, 65, 113, 1);
  --el-radio-button-checked-font-color: var(--el-color-white);
  --el-radio-button-checked-border-color: rgba(31, 65, 113, 1);
  --el-radio-button-disabled-checked-fill: var(--el-border-color-extra-light);
}
</style>
<style lang="less" scoped>
@media screen and(max-width:900px) {
  .outside {
    .top_header {
      margin: 10px 0;
    }
    .time_title {
      margin: 10px 0;
    }
    .left {
      width: 100%;
      margin: 0;
      height: 200px;
      .left_tab {
        min-height: auto;
        .No_news_yet {
          margin: 50px 0 !important;
          img {
            width: 50px;
          }
        }
      }
      ::v-deep .el-tabs__content{
        height: 160px!important;
        overflow-y: scroll!important;
      }
    }
    .middle {
      margin: 0;
      min-width: 100% !important;
      .list {
        height: 270px;
      }
      .el-button--primary.is-plain {
        width: 22%;
        font-size: 12px;
        padding: 10px;
      }
      .shoot_screen_icon {
        margin-left: 5px;
        width: 20px;
        height: 20px;
      }
      .el-button + .el-button,
      .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        width: 22%;
        font-size: 12px;
        padding: 10px;
      }
      .operation {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
</style>
