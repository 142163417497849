import service from '@/utils/request'

/**
 * 获取展示标签
 * */
export function getTagList(params) {
    return service({
        url: '/slipper/api/conversation/label/show',
        method: 'post',
        data: params
    })
}


/**
 * 获取全部标签
 * */
export function getLabelList() {
    return service({
        url: '/slipper/api/label/list',
        method: 'post',
    })
}

/**
 * 获取选中的标签
 * */
export function getSelectLabelList(params) {
    return service({
        url: '/slipper/api/conversation/label/select',
        method: 'post',
        data: params
    })
}

/**
 * 添加标签
 * */
export function addTags(params) {
    return service({
        url: '/slipper/api/conversation/label/create',
        method: 'post',
        data: params
    })
}

/**
 * 更新、修改选中标签
 * */
export function updateTags(params) {
    return service({
        url: '/slipper/api/conversation/label/update',
        method: 'post',
        data: params
    })
}

/**
 *删除标签
 * */
export function deleteTag(params) {
    return service({
        url: '/slipper/api/conversation/label/delete',
        method: 'post',
        data: params
    })
}
/**
 * 服务标记
 *  添加备注
 * */
export function addTagMark(params){
    return service({
        url:'/slipper/api/conversation/remark/update',
        method:'post',
        data:params,
    })
}
/**
 * 追踪标记
 *
 * */
export function addTrackOrMark(params){
    return service({
        url:'/slipper/api/conversation/track',
        method:'post',
        data:params,
    })
}